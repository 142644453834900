<template>
  <div>
    <div v-if="installation">
      <FullscreenImage :src="getImage" v-if="fullscreen" @close="toggleFullscreen"></FullscreenImage>
      <div class="row">
        <div class="col-1">
          <button class="change-index-button previous-index-button" v-if="$route.path.slice(14) != 1" @click="changePoem(-1)">Forrige dikt</button>
        </div>
        <div class="col-10"></div>
        <div class="col-1">
          <button class="change-index-button next-index-button" v-if="$route.path.slice(14) != 118" @click="changePoem(1)">Neste dikt</button>
        </div>
        </div>
      <div class="row">
        <div class="col-4">
          <img
            :src="getImage"
            :alt="'Bilde av diktet: ' + installation.title"
            @click="toggleFullscreen"
          />
          <h4>Område: {{ installation.location }}</h4>
          <div class="mapWrapper">
            <GMapMap
              id="map"
              api-key="AIzaSyAZx4cMuWZ8ZoqddymU6G_TYpu5D0NSofg"
              :center="installation.position"
              :zoom="18"
              tilt: 0
              mapTypeId="satellite"
              style="width: 100%; height: 30vh"
            >
              <!-- <GmapCustomMarker :marker="installation.position">
                <div class="marker">
                  <div class="mini-map-content" v-if="openInstallation">
                    <span class="name">{{ installation.title }}</span>
                    <hr />
                    <img
                    loading="lazy"
                      class="mini-map-pic"
                      :src="getImage"
                      :alt="'Bilde av diktet: ' + installation.title"
                    />
                  </div>
                  <div
                    @click="openInstallation = !openInstallation"
                    class="dot"
                    :class="{ active: openInstallation }"
                  >
                    {{ installation.id }}
                  </div>
                </div>
              </GmapCustomMarker> -->
            </GMapMap>
          </div>
        </div>
        <div class="col-8">
          <h2 tabindex="0">#{{ installation.id }} - {{ installation.title }}</h2>
          <h4 tabindex="0">
            Forfatter:
            <a
              v-if="installation.link"
              :href="installation.link"
              target="_blank"
              >{{ installation.author }}</a>
              <span v-else>{{ installation.author }}</span>
          </h4>
          <h4 tabindex="0" v-if="installation.sponsor">
            Bidragsyter: {{ installation.sponsor }}
          </h4>
          <h4 tabindex="0" v-if="installation.musicLink">
            <a :href="installation.musicLink" target="_blank" rel="noreferrer">Hør diktet sunget</a>
          </h4>
          <h4>
            <span v-for="(file, index) in audio" :key="index">
              <span tabindex="0"
                v-if="file.slice(18, 21).replace(/\D/g,'') == installation.id"
              >
                <a
                  :href="'../wp-content' + file.slice(1)"
                  target="_blank"
                  rel="noopener noreferrer"
                  >
                  <!-- {{ file.slice(1) }} -->
                  Hør diktet lest
                  </a>
                  <br>
              </span>
            </span>
          </h4>
          <div tabindex="0" class="content">
            <div v-html="installation.content"></div>
            <img
              :src="installation.img"
              alt="Bilde av 'bølgen'"
              v-if="installation.id == 15"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import GmapCustomMarker from "vue3-gmap-custom-marker";
import FullscreenImage from "@/components/modals/FullscreenImage.vue";
export default {
  name: "InstallationView",
  components: {
    // GmapCustomMarker,
    FullscreenImage,
  },
  data() {
    return {
      fullscreen: false,
      installation: null,
      openInstallation: false,
      image: null,
      audio: [],
    };
  },
  async mounted() {
    await this.getInstallation();
    this.importAll(require.context("../../public/wp-content", true, /\.mp3$/));
  },

  methods: {
    importAll(r) {
      r.keys().forEach((key) => this.audio.push(key));
    },
    goToMediaFile(target) {
      this.$router.push(target);
    },
    async getInstallation() {
      await this.$store.dispatch("getInstallations");
      let installations = await this.$store.state.installations;
      let installation = installations.filter(
        (x) => x.id == this.$router.currentRoute.value.params.id
      );
      this.installation = installation[0];
    },
    changePoem(n) {
      let res =
        this.$route.path.slice(0, 14) +
        (Number(this.$route.path.slice(14)) + n);
      this.$router.push(res);
      this.getInstallation();
    },
    toggleFullscreen() {
      this.fullscreen = !this.fullscreen
    }
  },
  computed: {
    getImage() {
      return this.installation.img != undefined
        ? this.installation.img
        : require("../assets/fallback.jpg");
    },
  },
};
</script>

<style scoped>
.change-index-button {
  width: 100%;
  margin: 10px 0;
  padding: 10px;
  font-size: 1em;
  background-color: #2a8355;
  color: #ffffff;
}
/* 
.previous-index-button {
}
.next-index-button {
} */
.installation-image {
  width: 100%;
}
.content {
  padding: 15px;
  width: fit-content;
  background-color: #c4c4c455;
  white-space: pre-wrap;
  font-family: "Franklin Gothic Medium";
}
img {
  width: 100%;
}

.marker {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.dot {
  margin-bottom: -2.25em;
  height: 2em;
  width: 2em;
  border-radius: 50%;
  background: red;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  color: white;
  z-index: 3;
}
.mini-map-content {
  padding: 15px;
  width: 100px;
  background-color: #c4c4c455;
  white-space: pre-wrap;
  font-family: "Franklin Gothic Medium";
}
.mini-map-pic {
  width: 100%;
}
img {
  width: 100%;
  object-fit: contain;
}
</style>