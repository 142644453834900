<template>
  <ModalXD>
    <div class="news-item-container">
      <div class="input-wrapper">
        <h1 class="title">Rediger nyhet</h1>
      </div>
      <div class="input-wrapper">
        <div>
          <label> Redaktør: </label>
          <input type="text" placeholder="Redaktør" v-model="newsItem.author" />
        </div>
      </div>
      <div class="input-wrapper">
        <div>
          <label> Tittel: </label>
          <input type="text" placeholder="Tittel" v-model="newsItem.title" />
        </div>
      </div>
      <div class="input-wrapper">
        <div class="image-container" v-if="imageChanged">
          <p>Nytt bilde valgt</p>
        </div>
        <div class="image-container" v-else>
          <img :src="this.newsItem.img" alt="Bilde" id="image-view" />
          <div></div>
          <div></div>
          <div></div>
        </div>
        <input
          type="file"
          ref="newsitemfile"
          class="image-selector"
          accept="image/*"
          @change="image = $event.target.files[0]"
        />
      </div>
      <div class="input-wrapper">
        <label>Bilde beskrivelse:</label>
        <input
          type="text"
          placeholder="Beskrivelse"
          v-model="newsItem.description"
        />
      </div>
      <div class="input-wrapper">
        <div>
          <label> Innhold: </label>
          <Editor
            api-key="l3hxtpsgpdlf3ypuwfuqta730sunu7tbe9u1kqc4kjgdvxds"
            :init="{
              toolbar_mode: 'sliding',
              plugins: [
                // Core editing features
                'autolink',
                'lists',
                'searchreplace',
                'table',
                'wordcount',
              ],
              toolbar:
                'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | align lineheight | checklist numlist bullist indent outdent | removeformat',
              tinycomments_mode: 'embedded',
              tinycomments_author: 'Author name',
            }"
            initial-value="Welcome to TinyMCE!"
            v-model="newsItem.content"
          />
        </div>
      </div>
      <div class="input-wrapper">
        <button @click="updateNewsItem">
          Oppdater<span class="material-icons">publish</span>
        </button>
        <button @click="cancelUpdate" class="cancel-button">
          Avbryt<span class="material-icons">cancel</span>
        </button>
      </div>
    </div>
  </ModalXD>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
import S3 from "@/exports/s3";
import uniqid from "uniqid";
import ModalXD from "@/components/ModalXD.vue";
export default {
  name: "ModalEdit",
  components: {
    Editor,
    ModalXD,
  },
  watch: {
    image() {
      this.imageChanged = true;
    },
  },
  props: {
    selectedNewsItem: Object,
  },
  data: () => ({
    image: null,
    newsItem: {},
    imageChanged: false,
  }),
  mounted() {
    this.getNewsItem();
  },
  methods: {
    async getNewsItem() {
      this.newsItem = this.selectedNewsItem;
    },
    updateNewsItem() {
      if (this.imageChanged) {
        if (this.newsItem.img) {
          const decodedURI = (img) =>
            decodeURIComponent(`${img.split("/")[3]}/${img.split("/")[4]}`);
          S3.delete({
            Bucket: "poesiparken",
            Key: decodedURI(this.newsItem.img),
          }).then(() => {
            S3.upload({
              Bucket: "poesiparken",
              Key: `News/(${uniqid()})${this.image.name}`,
              Body: this.image,
              ContentType: this.image.type,
            }).then(async (data) => {
              let payload = this.newsItem;
              payload.img = data.Location;
              await this.$store.dispatch("updateNewsItem", payload);
              this.$emit("close");
              this.imageChanged = false;
            });
          });
        } else {
          S3.upload({
            Bucket: "poesiparken",
            Key: `News/(${uniqid()})${this.image.name}`,
            Body: this.image,
            ContentType: this.image.type,
          }).then(async (data) => {
            let payload = this.newsItem;
            payload.img = data.Location;
            await this.$store.dispatch("updateNewsItem", payload);
            this.$emit("close");
            this.imageChanged = false;
          });
        }
      } else {
        (async () => {
          let payload = this.newsItem;
          await this.$store.dispatch("updateNewsItem", payload);
          this.$emit("close");
        })();
      }
    },
    cancelUpdate() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped lang="scss">
.news-item-container {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 80vh;
  .input-wrapper {
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    width: 100%;
    * {
      width: 100%;
      margin: 5px;
      margin-right: 15px;
      padding: 0;
    }
    button {
      margin: 0px;
      padding: 20px;
      font-size: 1em;
      background: #2a8355;
      color: white;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      span {
        margin: 0 5px;
        width: fit-content;
      }
    }
    .cancel-button {
      background: #991111;
    }
  }
}
.txt-ara {
  height: 10em;
  white-space: pre-wrap;
}

.title {
  font-size: 2em;
  text-decoration: underline;
  text-decoration-color: #2a8355;
}
.content {
  display: grid;
  grid-auto-flow: row;
  gap: 10px;
}
.content input {
  padding: 20px;
  font-size: 1em;
  background: transparent;
  border: none;
  border-bottom: solid 1px black;
}
.content textarea {
  padding: 20px;
  font-size: 1em;
  background: transparent;
  border: solid 1px black;
  resize: none;
}
.content button {
  padding: 20px;
  font-size: 1em;
  background: #2a8355;
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-container {
  display: flex;
  flex-direction: row;
  height: 64px;
  width: 64px;
}

.image-selector {
  width: 50px;
  border: 1px solid black;
  font-size: 0;
}

::file-selector-button {
  font-size: initial;
}

#image-view {
  height: 100%;
  width: 100%;
}
</style>
