<template>
  <ModalXD>
    <div class="news-container">
      <div class="input-wrapper">
        <h1 class="title">Legg til nyhet</h1>
      </div>
      <div class="input-wrapper">
        <div>
          <label> Redaktør: </label>
          <input type="text" placeholder="Redaktør" v-model="newsItem.author" />
        </div>
      </div>
      <div class="input-wrapper">
        <div>
          <label> Tittel: </label>
          <input type="text" placeholder="Tittel" v-model="newsItem.title" />
        </div>
      </div>
      <div class="input-wrapper">
        <label>Bilde:</label>
        <input
          type="file"
          ref="newsitemfile"
          class="image-selector"
          accept="image/*"
          @change="newsItem.img = $event.target.files[0]"
        />
      </div>
      <div class="input-wrapper">
        <label>Bilde beskrivelse:</label>
          <input type="text" placeholder="Beskrivelse" v-model="newsItem.description" />
      </div>
      <div class="input-wrapper">
        <div>
          <label> Innhold: </label>
          
      <Editor
        api-key="l3hxtpsgpdlf3ypuwfuqta730sunu7tbe9u1kqc4kjgdvxds"
        :init="{
          toolbar_mode: 'sliding',
          plugins: [
            // Core editing features
            'autolink',
            'lists',
            'searchreplace',
            'table',
            'wordcount',
          ],
          toolbar:
            'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | align lineheight | checklist numlist bullist indent outdent | removeformat',
          tinycomments_mode: 'embedded',
          tinycomments_author: 'Author name',
        }"
        initial-value="Welcome to TinyMCE!"
        v-model="newsItem.content"
      />
        </div>
      </div>
      <div class="input-wrapper">
        <button @click="publishNewsItem">
          Publiser<span class="material-icons">publish</span>
        </button>
      </div>
    </div>
  </ModalXD>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
import ModalXD from "@/components/ModalXD.vue";
import S3 from "@/exports/s3";
import uniqid from "uniqid";
export default {
  name: "ModalNews",
  components: {
    Editor,
    ModalXD,
  },
  data() {
    return {
      center: { lat: 59.05328, lng: 10.03517 }, //Larvik, Norge
      zoomLevel: 12,
      selectedFile: null,
      newsItem: {
        uniqid: null,
        id: 0,
        title: "",
        content: "",
        author: "",
        img: null,
        description: "",
        authorTimestamp: null,
        editorTimestamp: null,
      },
      sponsors: ["Sparebank1", "", ""],
    };
  },
  methods: {
    fileName(e) {
      this.selectedFile = e.target.files[0].name;
    },
    async publishNewsItem() {
      if (this.newsItem.img) {
        S3.upload({
          Bucket: "poesiparken",
          Key: `News/(${uniqid()})${this.newsItem.img.name}`,
          Body: this.newsItem.img,
          ContentType: this.newsItem.img.type,
        }).then(async (data) => {
          this.newsItem.uniqid = uniqid();
          let payload = this.newsItem;
          payload.img = data.Location;
          payload.authorTimestamp = Date.now();
          await this.$store.dispatch("addNewsItem", payload);
          this.$emit("close");
          this.$toast.success("Nyhet lagt til med bilde");
        });
      } else
        (async () => {
          this.newsItem.uniqid = uniqid();
          let payload = this.newsItem;
          payload.authorTimestamp = Date.now();
          await this.$store.dispatch("addNewsItem", payload);
          this.$emit("close");
          this.$toast.success("Nyhet lagt til uten bilde");
        })();
    },
  },
};
</script>

<style scoped lang="scss">
.news-container {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  .input-wrapper {
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    width: 100%;
    * {
      width: 100%;
      margin: 5px;
      margin-right: 15px;
      padding: 0;
    }
    button {
      margin: 0px;
      padding: 20px;
      font-size: 1em;
      background: #2a8355;
      color: white;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      span {
        margin: 0 5px;
        width: fit-content;
      }
    }
  }
}

p {
  margin: 0;
  padding: 0;
  font-size: 1em;
}

.txt-ara {
  height: 10em;
  white-space: pre-wrap;
}

.custom-file-upload {
  padding: 20px;
  font-size: 1em;
  background: #2a8355;
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

</style>
